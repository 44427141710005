import PropTypes from 'prop-types';
import { useState, useCallback, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import {
  Checkbox, FormControlLabel, FormGroup, Grid,
} from '@mui/material';

import {
  FormTextField,
  Button,
  FormActionWrapper,
} from '~common/components';
import { validationSchemas } from '~common/utils';
import checkEmailExists from '@patheducation/admin/src/utils/checkEmail.js';
import useStyles from './styles';

// Email regex pattern
const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~\-]+(\.[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~\-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const AccountPanel = ({ formData, onSubmit }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const {
    handleSubmit, control, setError, clearErrors,
  } = useForm(
    { defaultValues: formData, resolver: yupResolver(validationSchemas.accountPanelSchema) },
  );

  const email = useWatch({
    control,
    name: 'emailAddress',
  });

  const isValidEmail = (email) => emailRegex.test(email);

  const debouncedCheckEmail = useCallback(
    (() => {
      let timer;
      return (email) => {
        clearTimeout(timer);
        timer = setTimeout(async () => {
          if (email && isValidEmail(email)) {
            try {
              const exists = await checkEmailExists(email);
              setEmailExists(exists);
              if (exists) {
                setError('emailAddress', {
                  type: 'manual',
                  message: 'This email is already in use',
                });
              } else {
                clearErrors('emailAddress');
              }
            } catch (error) {
              console.error('Error checking email:', error);
              setError('emailAddress', {
                type: 'manual',
                message: 'Error checking email availability',
              });
            }
          } else if (email) {
            // If there's input but it's not a valid email
            setError('emailAddress', {
              type: 'manual',
              message: 'Please enter a valid email address',
            });
          } else {
            // If there's no input, clear any existing errors
            clearErrors('emailAddress');
          }
        }, 500);
      };
    })(),
    [setError, clearErrors],
  );

  useEffect(() => {
    debouncedCheckEmail(email);
  }, [email, debouncedCheckEmail]);

  const handleFormSubmit = (data) => {
    if (!emailExists && isValidEmail(data.emailAddress)) {
      onSubmit(data);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className={classes.formPanel} noValidate>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} sm={6}>
          <FormTextField
            name="firstName"
            control={control}
            inputProps={{
              label: 'First Name',
              autoFocus: true,
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            name="lastName"
            control={control}
            inputProps={{
              label: 'Last Name',
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            name="emailAddress"
            control={control}
            inputProps={{
              label: 'Email Address',
              required: true,
              className: classes.emailInput,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            name="password"
            control={control}
            inputProps={{
              label: 'Password',
              type: showPassword ? 'text' : 'password',
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            name="confirmPassword"
            control={control}
            inputProps={{
              label: 'Confirm',
              type: showPassword ? 'text' : 'password',
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={showPassword}
                  onChange={() => setShowPassword(!showPassword)}
                  className={classes.checkbox}
                />
              )}
              className={classes.label}
              label="Show Password"
            />
          </FormGroup>
          <FormActionWrapper>
            <Button onClick={() => navigate('/')} variant="text" className={classes.backLink} disableRipple>
              Log in instead
            </Button>
            <Button type="submit" disabled={emailExists || !isValidEmail(email)}>Next</Button>
          </FormActionWrapper>
        </Grid>
      </Grid>
    </form>
  );
};

AccountPanel.propTypes = {
  formData: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AccountPanel;
