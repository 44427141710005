import { API } from '@aws-amplify/api';
import { config } from '~common/utils';

export default async function checkEmailExists(email) {
  const path = config.path.getEmailExists;
  const { apiName } = config;

  const res = await API.get(apiName, `${path}?email=${encodeURIComponent(email)}`, { email });
  return res.exists;
}
